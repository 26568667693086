.container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100vw;
   background: hsl(var(--h), 40%, 33%);
   font-family: Arial Black,Arial Bold,Gadget,sans-serif;
   font-weight: bold;
   font-size: 2em;
   color:  #ffffff;
}